import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { getFeed } from '../../../selectors';
import { translate, DATE_FORMATS } from '../../../../core';
import { baseColors, spacing, appFonts, imageSize } from '../../../../../styles';
import { CONTENT_TYPES, STREAM_ITEM_TYPE_SLUGS } from '../../../constants';

export default function WithFeedItemEntityCardBaseBase(WrappedComponent) {
    class FeedItemEntityCardBase extends PureComponent {
        static propTypes = {
            streamItemId: PropTypes.number.isRequired, // eslint-disable-line
            stream: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            onEntityDetails: PropTypes.func.isRequired
        };

        get isMember() {
            if (_.has(this.props, 'stream.isMember') && this.props.stream.relationTypeSlug === 'group') {
                return this.props.stream.isMember;
            }
            return this.props.stream.viewer ? !!this.props.stream.viewer.isMember : false;
        }

        _amountMembers(field) {
            return this.props.stream[field] ? this.props.stream[field] : this.props.stream.relatedItemDetails[field];
        }

        get formatEventDate() {
            if (!this.props.stream.relatedItemDetails.eventDateTime) return '';
            const date = moment(this.props.stream.relatedItemDetails.eventDateTime);
            return date.minutes()
                ? date.format(DATE_FORMATS.monthFullDayShortTime)
                : date.format(DATE_FORMATS.monthFullDayTwelveHoursTime);
        }

        get isDeletedRelatedItem() {
            return !_.size(this.props.stream.relatedItemDetails);
        }

        determineContent = () => {
            const { i18n, stream } = this.props;
            const groupName = _.get(stream, 'streamItemDisplay.generatedText.values.group_name');
            const content = {};
            const relatedItemDetails = stream.relatedItemDetails || {};
            const notMemberParticipantsNum = num => num === 1 ? i18n.t('oneParticipant') : i18n.t('numberOfParticipants', { num });
            switch (stream.streamItemTypeSlug) {
                case STREAM_ITEM_TYPE_SLUGS.add_group: {
                    content.image = relatedItemDetails.groupImageURL;
                    const name = relatedItemDetails.groupName || groupName;
                    if (this.isDeletedRelatedItem) {
                        content.name = i18n.t('entityHasBeenDeleted', { name });
                    } else {
                        content.name = name;
                        const num = this._amountMembers('numMembers');
                        const notMemberNumMembers = num === 1 ? i18n.t('oneParticipant') : i18n.t('numberOfMembers', { num });
                        content.additionalData = this.isMember
                            ? i18n.t('numberOfMembersWithUser', { num })
                            : notMemberNumMembers;
                    }
                    content.icon = 'users';
                    content.type= CONTENT_TYPES.community;
                    break;
                }
                case STREAM_ITEM_TYPE_SLUGS.add_event:
                case STREAM_ITEM_TYPE_SLUGS.add_company_event:
                    content.name = relatedItemDetails.eventName;
                    content.image = relatedItemDetails.eventImageURL;
                    content.additionalData = this.formatEventDate;
                    if (relatedItemDetails.numEventAttendees) {
                        const num = this._amountMembers('numEventAttendees');
                        const notMemberAttendeesNum = num === 1 ? i18n.t('oneParticipant') : i18n.t('numberOfAttendees', { num });
                        content.additionalData += this.isMember
                            ? i18n.t('numberOfAttendeesWithUser', { num })
                            : notMemberAttendeesNum;
                    }
                    content.icon = 'calendar-alt';
                    content.type= CONTENT_TYPES.event;
                    break;
                case STREAM_ITEM_TYPE_SLUGS.join_event_group_challenge: {
                    content.name = relatedItemDetails.eventName;
                    content.image = relatedItemDetails.eventImageURL;
                    const num = this._amountMembers('numEventAttendees');
                    content.additionalData = relatedItemDetails.numChallengeParticipants && (this.isMember
                        ? i18n.t('numberOfParticipantsWithUser', { num })
                        : notMemberParticipantsNum(num));
                    content.icon = 'trophy-alt';
                    content.type= CONTENT_TYPES.challenge;
                    break;
                }
                case STREAM_ITEM_TYPE_SLUGS.add_challenge:
                case STREAM_ITEM_TYPE_SLUGS.group_join_challenge: {
                    content.name = relatedItemDetails.challengeName;
                    content.image = relatedItemDetails.challengeImageURL;
                    const num = this._amountMembers('numChallengeParticipants');
                    content.additionalData = relatedItemDetails.numChallengeParticipants && (this.isMember
                        ? i18n.t('numberOfParticipantsWithUser', { num })
                        : notMemberParticipantsNum(num));
                    content.icon = 'trophy-alt';
                    content.type= CONTENT_TYPES.challenge;
                    break;
                }
                case STREAM_ITEM_TYPE_SLUGS.add_user:
                case STREAM_ITEM_TYPE_SLUGS.add_company_notification:
                case STREAM_ITEM_TYPE_SLUGS.new_event_group_challenge:
                    break;
                default:
            }
            return content;
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    content={this.determineContent()}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            stream: getFeed(state, ownProps.streamItemId)
        };
    }

    return connect(mapStateToProps)(translate()(FeedItemEntityCardBase));
}

export const ENTITY_CARD_BORDER_RADIUS = 8;
export const SHADOW = { opacity: 0.15, height: 8 };

export const styles = {
    entityCard: {
        backgroundColor: baseColors.white,
        margin: spacing.s1,
        borderRadius: ENTITY_CARD_BORDER_RADIUS,
    },
    entityCardImageContainer: {
        borderTopLeftRadius: ENTITY_CARD_BORDER_RADIUS,
        borderTopRightRadius: ENTITY_CARD_BORDER_RADIUS,
        overflow: 'hidden',
        position: 'relative',
    },
    entityCardImage: {
        height: imageSize.lg
    },
    entityContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    entityIcon: {
        backgroundColor: baseColors.transparent,
        marginTop: spacing.s0 + 2,
        marginRight: spacing.s3
    },
    entityInnerContainer: {
        flex: 1
    },
    entityName: {
        ...appFonts.lgMedium,
    },
    entityAdditionalData: {
        ...appFonts.smRegular,
        color: baseColors.grey40,
    },
    joinButton: {
        marginTop: spacing.s3
    },
    imageTop: {
        paddingTop: spacing.s1
    },
};

