import _ from 'lodash';
import moment from 'moment';
import { ICON_CATEGORIES, DATE_FORMATS } from '../../../core';
import shareActivity from './shareActivity';
import { dateRange } from '../helper';

export const deleteActivityData = (activity, pendingActivities, changedActivities, deletedActivities, allLogs, activityLogs) => {
    const existingActivity = hasActivityLog(allLogs, activity);
    return existingActivity ? {
        deletedActivities: [...deletedActivities, activity],
        activityLogs: removeActivity(activityLogs, activity),
        changedActivities: removeActivity(changedActivities, activity),
        pendingActivities: removeActivity(pendingActivities, activity)
    } : {
        activityLogs: removeActivity(activityLogs, activity),
        pendingActivities: removeActivity(pendingActivities, activity)
    };
};

export const removePendingActivity = (activity, pendingActivities, changedActivities) => ({
    changedActivities: removeActivity(changedActivities, activity),
    pendingActivities: removeActivity(pendingActivities, activity)
});

const hasActivityLog = (activities, activity) => _.find(
    activities,
    ac => {
        if (_.get(ac, 'activityLogId') && _.get(activity, 'activityLogId')) {
            return ac.activityLogId === activity.activityLogId;
        }
        if (ac.activityId === activity.activityId) {
            return ac.activityUnitId === activity.activityUnitId;
        }
        return ac.activityId === activity.activityId;
    }
);
const removeActivity = (activities, activity) => _.filter(
    activities,
    ac => {
        if (_.get(ac, 'activityLogId') && _.get(activity, 'activityLogId')) {
            return ac.activityLogId !== activity.activityLogId;
        }
        if (ac.activityId === activity.activityId) {
            return ac.activityUnitId !== activity.activityUnitId;
        }
        return ac.activityId !== activity.activityId;
    }
);

export const updateActivityData = (activity, allLogs, pendingActivities, changedActivities, deletedActivities, activities = []) => {
    const pendingActivity = hasActivityLog(pendingActivities, activity);
    const existingActivity = hasActivityLog(allLogs, activity);
    const isDeleted = hasActivityLog(deletedActivities, activity);

    const newState = pendingActivity ? {
        pendingActivities: [
            activity,
            ...removeActivity(pendingActivities, pendingActivity)
        ],
    } : {
        pendingActivities: [activity, ...pendingActivities],
        activities: removeActivity(activities, activity),
    };

    if (existingActivity && !isDeleted) {
        const changedActivity = hasActivityLog(changedActivities, activity);
        _.assign(newState, {
            changedActivities: changedActivity ? [
                activity,
                ...removeActivity(changedActivities, changedActivity)
            ] : [activity, ...changedActivities]
        });
    }

    return { ...newState, searchText: '', lastAddedItem: activity };
};


const _trackActivities = (id, pendingActivities, changedActivities, deletedActivities, filterId, text = '', image = '',
    isSingleChallenge, changeOrDeleteCallback, trackCallback) => {
    changedActivities.length && changeOrDeleteCallback(id, _.map(changedActivities, 'activityLogId'), false);
    deletedActivities.length && changeOrDeleteCallback(id, _.map(deletedActivities, 'activityLogId'));
    pendingActivities.length && trackCallback(id, pendingActivities, filterId, text, image, isSingleChallenge);
};

// eslint-disable-next-line no-unused-vars
const _shareActivity = (id, pendingActivities, changedActivities, deletedActivities, filterId, isSingleChallenge,
    changeOrDeleteCallback, trackCallback, navigation) => {
    shareActivity(id, pendingActivities, changedActivities, deletedActivities, filterId, isSingleChallenge,
        _trackActivities, changeOrDeleteCallback, trackCallback, navigation);
};

export const submitActivities = (id, pendingActivities, changedActivities, deletedActivities, filterId, customizePost,
    isSingleChallenge, changeOrDeleteCallback, trackCallback, navigation) => {
    if (pendingActivities.length > 0 || deletedActivities.length > 0 || changedActivities.length > 0) {
        _trackActivities(id, pendingActivities, changedActivities, deletedActivities, filterId, false,
            false, isSingleChallenge, changeOrDeleteCallback, trackCallback);
    }
};

export function getTotalPointsEarned(activities, deletedActivities = []) {
    const tracked = _.reduce(activities, (total, activity) =>
        total + Math.round(((parseFloat(activity.unitPoints || _.get(activity, 'points', 0)) * _.toNumber(activity.quantity)) * 100) / 100), 0);
    const deleted = _.reduce(deletedActivities, (total, activity) =>
        total + Math.round(((parseFloat(activity.unitPoints || _.get(activity, 'points', 0)) * _.toNumber(activity.quantity)) * 100) / 100), 0);
    return tracked - deleted;
}

export function getTotalActivityNames(activities) {
    return _.uniq(_.map(activities, 'activityName')).join(', ');
}

export function groupActivityLogsByDay(activityLogs, withSum) {
    return withSum
        ? _.map(_.groupBy(activityLogs, 'date'), (logs, date) => ({
            logs,
            date,
            points: _.reduce(logs, (sum, log) => sum + _.get(log, 'points', 0), 0),
            unitQuantity: _.reduce(logs, (sum, log) => sum + _.get(log, 'quantity', 0), 0)
        }))
        : _.map(_.groupBy(activityLogs, 'date'), (logs, date) => ({ logs, date }));
}

export function groupActivityLogsByWeek(activityLogs, challengeStartDate, isRestartGoal = false, deadline, isEnded) {
    const weekType = isRestartGoal ? 'isoWeek' : 'week';
    const datesFrom = datesUntilDeadline(challengeStartDate, deadline, isEnded);
    const datesWithLogs = _.map(activityLogs, log => log.date);
    const noLogsArray = _.filter(_.map(datesFrom, date => ({ date })), obj => !_.includes(datesWithLogs, obj.date));
    const MAX_WEEK_COUNT = 13; // 1 current and 12 previous

    return _.chain([...noLogsArray, ...activityLogs])
        .groupBy(log => moment(log.date).startOf(weekType).week())
        .map(logByWeek => groupActivityLogsByDay(_.orderBy(logByWeek, ['date'], ['desc']), true))
        .sortBy(item => moment(item[0].date).format('X'))
        .map((logs, index) => ({
            logs,
            period: getWeekString(logs[0].date, isRestartGoal, index === 0 && challengeStartDate),
            pointsPerWeek: _.reduce(logs, (sum, log) => sum + _.get(log, 'points', 0), 0),
        }))
        .reverse()
        .slice(0, MAX_WEEK_COUNT)
        .value();
}

export function groupActivityLogsByWeekPeriod(activityLogs, periodStartDate, periodEndDate, isCurrent, periodId) {
    const periodEnd = moment(periodEndDate).isAfter(moment()) ? moment() : periodEndDate;

    const filteredLogs = _.filter(activityLogs, log => moment(log.date).isBetween(periodStartDate, periodEndDate, 'days', '[]'));
    const datesFrom = datesUntilDeadline(periodStartDate, periodEnd, !isCurrent);
    const datesWithLogs = _.map(filteredLogs, log => log.date);
    const noLogsArray = _.filter(_.map(datesFrom, date => ({ date })), obj => !_.includes(datesWithLogs, obj.date));
    const allLogs = [...noLogsArray, ...filteredLogs];

    let logsWithIds = [];
    _.map(allLogs, log => {
        const newLog = { ...log };
        newLog.perodId = periodId;
        logsWithIds = _.concat(logsWithIds, newLog);
    });

    return _.chain(logsWithIds)
        .groupBy(log => log.periodId)
        .map(logByWeek => groupActivityLogsByDay(_.orderBy(logByWeek, ['date'], ['desc']), true))
        .sortBy(item => moment(item[0].date).format('X'))
        .map((logs, index) => ({
            logs,
            period: dateRange({ startDate: periodStartDate, challengeDeadline: periodEnd }),
            pointsPerWeek: _.reduce(logs, (sum, log) => sum + _.get(log, 'points', 0), 0),
        }))
        .reverse()
        .value();
}

export function datesUntilDeadline(startDate, deadline, isEnded) {
    const start = moment(startDate).clone();
    const end = isEnded ? deadline : moment();
    const dates = [];

    while (start.isSameOrBefore(end)) {
        dates.push(start.format(DATE_FORMATS.full));
        start.add(1, 'days');
    }
    return _.reverse(dates);
}

export function datesInWeek(date, isRestartGoal) {
    const weekStart = moment(date).clone().startOf(isRestartGoal ? 'isoWeek' : 'week');
    const days = [];

    for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format(DATE_FORMATS.full));
    }
    return _.reverse(days);
}

export function datesInWeekPeriod(date) {
    const weekStart = moment(date);
    const days = [];

    for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format(DATE_FORMATS.full));
    }
    _.reverse(days);

    return _.filter(days, date => !moment(date).isAfter(moment()));
}

export function getWeekString(date, isRestartGoal, challengeStartDate) {
    const weekType = isRestartGoal ? 'isoWeek' : 'week';
    const startDate = challengeStartDate ? challengeStartDate : moment(date).startOf(weekType);
    const period = {
        startDate,
        challengeDeadline: moment(date).endOf(weekType),
    };
    return dateRange(period);
}

export function groupActivityLogsByDayObject(activityLogs, withSum) {
    const obj = _.groupBy(activityLogs, 'date');
    return !withSum ? obj : _.mapValues(obj, (logs, date) => ({
        logs,
        date,
        points: _.reduce(logs, (sum, log) => sum + _.get(log, 'points', 0), 0)
    }));
}

export function sortActivitiesByCategory(data, categoriesBySlug) {
    return _.keys(categoriesBySlug).length ? _.values(_.mapValues(
        _.groupBy(data, 'activityCategorySlug'),
        (value, key) => ({
            title: categoriesBySlug[key].activityCategoryDisplayName,
            color: _.get(ICON_CATEGORIES, [key, 'bgr']),
            data: _.sortBy(value, ['activityName'], ['asc'])
        })
    )) : [];
}

export const getActivityUnitName = (item, quantity = 0) => {
    if (item) {
        const { unitSingular, unitName } = item;
        return quantity === 1 && unitSingular ? unitSingular : unitName;
    }

    return '';
};

export const getfullActivityUnitName = activity => {
    switch (activity) {
        case 'min':
            return 'minutes';
        default:
            return activity;
    }
};

export function getPeriodSmartTarget(item, isRestartGoal, challenge) {
    let target;
    if (isRestartGoal) {
        const weekString = getWeekString(moment(), isRestartGoal, challenge.startDate);

        if (item.period === weekString) {
            target = _.get(challenge.activityUnitDetails[0], 'target_quantity');
        } else {
            const previousPeriods = challenge.progress.restartGoalPreviousPeriods;
            if (previousPeriods) {
                _.forEach(previousPeriods, period => {
                    if (item.period === getWeekString(period.endDate, isRestartGoal, period.startDate)) {
                        target = _.get(period, 'challengeTarget');
                    }
                });
                if (!target) {
                    target = _.get(challenge.activityUnitDetails[0], 'target_quantity');
                }
            }
        }
    }

    return target;
}

export const calendarLocaleConfig = locale => {
    const momentLocale = moment.localeData(locale);

    let daysShort = [];
    if (!_.includes(locale, 'en')) {
        _.forEach(momentLocale.weekdaysShort(), day => {
            daysShort.push(day.charAt(0).toUpperCase() + day.slice(1, (day.length - 1)));
        });
    } else {
        daysShort = momentLocale.weekdaysShort();
    }

    return {
        monthNames: momentLocale.months(),
        monthNamesShort: momentLocale.monthsShort(),
        dayNames: momentLocale.weekdays(),
        dayNamesShort: daysShort
    };
};

export const getSingleActivityTotal = (periodLogs, isDaily = false) =>
    isDaily
        ? _.reduce(periodLogs, (dailySum, dailyLog) => dailySum + _.get(dailyLog, 'quantity', 0), 0)
        : _.reduce(periodLogs, (weeklySum, weeklyLog) => weeklySum +
        _.reduce(weeklyLog.logs, (dailySum, dailyLog) => dailySum +
        _.get(dailyLog, 'quantity', 0), 0), 0);

export const daysWithActivities = activityLogs => {
    const days = _.map(activityLogs, weeklyLog =>
        _.map(weeklyLog.logs, dailyLog => dailyLog.date));
    return _.uniq(_.flatten(days));
};

export const arrayOfDatesInPeriod = (logDate, startDate, isFirstWeek, isRestartGoal) => {
    const datesInPeriod = datesInWeek(logDate, isRestartGoal);
    return _.filter(datesInPeriod, date => isFirstWeek
        ? !moment(date).isBefore(startDate) && !moment(date).isAfter(moment())
        : !moment(date).isAfter(moment()));
};

export const filteredActivities = activities =>
    activities.map(item => ({
        ...item,
        logs: _.uniqBy(item.logs, activity => activity.activityLogId)
    }));

export const filterDeletedActivities = (activitiesToRender, filteredActivities) => {
    const logs = _.flatMap(_.map(activitiesToRender, activity => activity.logs));
    const filteredLogs = _.flatMap(_.map(filteredActivities, activity => activity.logs));
    const removedLogsIds = _.map(_.differenceBy(logs, filteredLogs, 'activityLogId'), log => log.activityLogId);
    return _.compact(_.map(activitiesToRender, activity => {
        const filteredLogs = _.filter(activity.logs, log => !removedLogsIds.includes(log.activityLogId));
        return filteredLogs.length ? { ...activity, logs: filteredLogs } : null;
    }));
};

export const getDateName = (date, i18n) => {

    const activityDate = moment(date).format('YYYY-MM-DD');
    let activityDateName = moment(date).format('dddd');

    if (activityDate === moment().format('YYYY-MM-DD')) {
        activityDateName = i18n.t('today');
    }
    if (activityDate === moment().subtract(1, 'days').format('YYYY-MM-DD')) {
        activityDateName = i18n.t('yesterday');
    }

    return activityDateName;
};

export const getDateString = date => `${moment(date).format('MMM D')}`;

export const overTrackedDateFormatted = (date, i18n) => {
    let dateFormatted = `${moment(date).format(DATE_FORMATS.dayFullString)} ${moment(date).format(DATE_FORMATS.monthDayYearFull)}`;
    const isToday = moment(date).isSame(moment(), 'day');
    const isYesterday = moment(date).isSame(moment().subtract(1, 'd'), 'day');
    if (isToday) {
        dateFormatted = i18n.t('today');
    } else if (isYesterday) {
        dateFormatted = i18n.t('yesterday');
    }
    return dateFormatted;
};

export const getRecentActivities = (allActivities, challenge = null) => {
    if (!challenge) return allActivities;

    return _.filter(allActivities, activity => _.includes(challenge.activityUnitsIds, _.toString(activity.activityUnitId)));
};
