export const NAME = 'streams';
export const NOT_PERSISTED = false;

export const STREAM_ENTITY_TYPES = Object.freeze({
    feed: 'feed',
    group: 'group',
    challenge: 'challenge',
    individuals: 'individuals',
    event: 'event'
});

export const STREAM_ENTITY_ID_DEFAULT = '0';
export const INAPPROPRIATE_IMAGE_ERROR = 406;

export const STREAM_ITEM_TYPE_SLUGS = Object.freeze({
    status_update: 'status_update',
    add_activities: 'add_activities',
    add_notification: 'add_notification',
    new_event_group_challenge: 'new_event_group_challenge',
    add_company_event: 'add_company_event',
    join_event_group_challenge: 'join_event_group_challenge',
    add_group: 'add_group',
    add_event: 'add_event',
    add_challenge: 'add_challenge',
    group_join_challenge: 'group_join_challenge',
    add_user: 'add_user',
    add_company_notification: 'add_company_notification'
});

export const CONTENT_TYPES = {
    challenge: 'challenge',
    event: 'event',
    community: 'community'
};

export const REFRESH_ALLOWED_DIFF = 5 * 60 * 1000; // 5min
